import './App.css';
// import { Home } from "./Home";
// import { Shop } from "./Shop";
// import { Labs } from './Labs';
// import { Tutorials } from './Tutorials';
// import About from "./About";
import { Lukes } from './Luke';

function App() {
  return (
    <div className="App">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="mt-2 text-light mx-auto nav nav-pills" id="pills-tab" role="tablist">
                        
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                               href="#home-tab" role="tab" aria-controls="pills-home"
                               aria-selected="true">LUKE</a>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-about-tab" data-bs-toggle="pill"
                               href="#about-tab" role="tab" aria-controls="pills-about"
                               aria-selected="false">About</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-research-tab" data-bs-toggle="pill"
                               href="#research-tab" role="tab" aria-controls="pills-research"
                               aria-selected="false">Research</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-tutorials-tab" data-bs-toggle="pill"
                               href="#tutorials-tab" role="tab" aria-controls="pills-tutorials"
                               aria-selected="false">Tutorials</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="pills-shop-tab" data-bs-toggle="pill"
                               href="#shop-tab" role="tab" aria-controls="pills-shop"
                               aria-selected="false">Shop</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
        <div className="tab-content mb-3" id="pills-tabContent">
            {/* <Home />
            <About />
            <Labs />
            <Shop />
            <Tutorials /> */}
            <Lukes/>
        </div>
        <footer className='py-3'>
        &copy; 2022
        </footer>
    </div>
  );
}

export default App;
