// import lab from './stogenielabs.JPG';
import ticket from './ticket.png';
import hogwarts from './hogwarts.jpeg';
import jeb from './jeb.jpg';
import ski from './ski.jpg'

export function Lukes() {
    return (
        <div className="tab-pane fade show active" id="home-tab" role="tabpanel" aria-labelledby="pills-home-tab">
            <div className='container py-3'>
                <div style={{maxWidth: '550px'}}>
                </div>
                <div className='card text-light bg-dark'>
                    <div className='card-body'>
                    <h3>🏔️ ⛷️ Luke's BIG Adventure Awaits! ⛷️ 🏔️</h3>
                    <img className='img-fluid' alt='tick' src={ticket} />
                    <img className='img-fluid' alt='hog' src={hogwarts} />
                    <img className='img-fluid' alt='jeb' src={jeb} />
                    <img className='img-fluid' alt='ski' src={ski} />
                    <p>see you in denver!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}